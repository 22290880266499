<template>
    <div class="w-full flex flex-col max-w-screen-md mx-auto">
        <div class="w-full flex flex-row gap-x-5">
            <!--Mode Select-->
            <div v-if="mode == ''" class="w-full flex flex-row items-center gap-x-5 justify-around py-10">
                <Button  class="flex-grow" icon="arrow-up-tray" label="Upload Board Packets" @click="mode='upload'" />
                <Button class="flex-grow"  icon="folder-open" label="Edit Packet Folders" @click="mode='folders'"  />
                <Button class="flex-grow" @click="this.$router.push('/admin/uaccess?boardcontrol=1')" icon="user" label="Add / Edit Board Members" />
            </div>
            <!--Upload Mode-->
            <Form v-else-if="mode == 'upload'" title="Board Packet Upload" submitText="Upload Files" @submit="uploadFile" class="w-full flex-grow" :showClose="true" @close="mode = ''">                
                <Notice v-if="uploadError != ''" :showClose="true" @close="uploadError = ''" title="Upload Error" :notice="uploadError" level="alert" />
                <Notice v-if="uploadNotice != ''" :showClose="true" @close="uploadNotice = ''" title="File Upload" :notice="uploadNotice" level="news" icon="arrow-up-tray" />
                <FileUpload ref="fileUpload" :folders="folders" :existingFiles="activeFolderFiles"  />
                <Field ref="destinationFolder" type="select" :options="folderOptions" @change="updateActiveFolders++" label="Packet Folder" />
            </Form>
            <!--Edit Folders Mode-->
            <div v-else-if="mode == 'folders'" class="w-full">
                <Form title="Packet Folders" :showSubmit="focusedFolder == ''" submitText="Add New Folder" @submit="addFolder()" :showClose="true" @close="mode = ''">
                <Notice v-if="folderError != ''" :showClose="true" @close="folderError = ''" :notice="folderError" level="alert" />
                <Notice v-if="folderNotice != ''" :showClose="true" @close="folderNotice = ''" :notice="folderNotice" level="news" icon="arrow-up-tray" />
                    <div class="flex flex-row items-center p-2 text-small">
                        <span class="text-xs font-bold">EDIT FOLDERS: </span>
                        <div class="flex flex-row p-2 gap-x-3">
                            <div v-for="folder in folders" :key="folder"
                                @click="focusedFolder = folder"
                                class="flex flex-row items-center py-1 text-sm cursor-pointer">
                                <Icon id="folder" class="h-5 w-5 opacity-50 mr-0.5" /><span>{{folder}}</span>
                            </div>
                        </div>
                    </div>
                    <div v-if="focusedFolder != ''" class="flex flex-col p-2 items-center w-full justify-between">
                        <div class="w-full flex flex-row items-center justify-center gap-x-2">
                            <Icon id="folder" class="h-6 w-6" />
                            <span class="text-lg">{{focusedFolder}}</span>
                            <span class="text-sm">(Contains {{filesInFolder(focusedFolder).length}} File<span v-if="filesInFolder(focusedFolder).length != 1">s</span>)</span>
                        </div>
                        <div class="w-full flex flex-row items-center">
                            <Button class="w-2/3" @click="deleteFolder(focusedFolder)" label="Delete Folder" icon="trash" type="cancel" />
                            <Button @click="focusedFolder = ''" label="Cancel" class="self-stretch w-1/3" />
                        </div>
                    </div>
                    <Field v-if="focusedFolder == ''" ref="newFolderName" type="text" label="New Folder Name" desc="Folder names cannot contain spaces" />
                </Form>
            </div>
        </div>
        
        <BoardPackets v-if="mode != 'folders'" :files="files" :folders="folders" :control="true" 
            @deleteFile="deleteFile" @deleteFiles="deleteFiles" />	
        
        <Notice v-if="mode == '' || mode == 'upload'" title="Deletion Notice" notice="Uploaded packets are automatically deleted 6 months after upload" icon="trash" level="notice" class="mb-2" />            
        
		
	</div>
</template>

<script>
	import BoardPackets from "@/components/BoardPackets.vue";
	import Button from "@/components/Button.vue";
	import Form from "@/components/Form.vue";
	import Field from "@/components/Field.vue";
	import FileUpload from "@/components/FileUpload.vue";
	import Icon from "@/components/Icon.vue";
	import Notice from "@/components/Notice.vue";
    import { ref } from 'vue'

	export default {
		components:{
            BoardPackets,
            Button,
			Form,
			Field,
            FileUpload,
            Icon,
            Notice
		},
		data(){
			return {
			};
		},
        computed: {
            activeFolderFiles(){
                this.updateActiveFolders++;
                let folder = this.$refs.destinationFolder ? this.$refs.destinationFolder.getValue() : ''
                return this.filesInFolder(folder);
            },
            folderOptions(){
                let options = [{label: 'No Folder', value: ''}];
                for(var ii = 0; ii < this.folders.length; ii++){
                    options.push({ label: this.folders[ii], value: this.folders[ii]})
                }
                return options;
            }
        },
        methods: {    
            async addFolder(){
                console.log('addFolder');

                //Make sure no spaces in folder name
                if(this.$refs.newFolderName.getValue().indexOf(' ') != -1){
                    this.folderError = 'Folder names cannot contain spaces';       
                    return;
                }
                //Make sure not an already used folder name
                if(this.folders.indexOf(this.$refs.newFolderName.getValue()) != -1){
                    this.folderError = 'Folder name already in use';       
                    return;
                }
                //Make sure folder name entered
                if(this.$refs.newFolderName.getValue().length == 0){
                    this.folderError = 'Folder name must be entered';       
                    return;
                }

               let data =  {
                    data: {
                            "attributes": {
                                "name": this.$refs.newFolderName.getValue(),
                            },
                            "type": "BoardPacketFolder",
                    }
                }                
                this.$fapi.post('boardpacket/files', data).then((response) =>{
                    console.log('addFolder.response: ', response);   
                    this.folderNotice = 'New folder has been added'
                    this.$refs.newFolderName.clear();      
                    this.readFiles();           
                }).catch((error) => {
                    
                    console.log('addFolder.error: ', error);
                    this.folderError = error;                    
                });
            },
            async deleteFile(file, dontRefresh){
                console.log('deleteFile: ', file);
                let idSplit = file.id.split('/');
                let len = idSplit.length;
                let id = idSplit[2];
                for(var ii = 3; ii < len; ii++){
                    id += '/' + idSplit[ii];
                }
                this.$fapi.delete(id).then((response) =>{
                    console.log('dele.teresponse: ', response);
                    if(!dontRefresh)
                        this.readFiles();
                }).catch((error) => {
                    console.log('deleteerror: ', error);                    
                });            
            },
            async deleteFiles(files){
                for(var key in files){
                    let file = files[key];
                    await this.deleteFile(file, true);
                }
                this.readFiles();
            },
            async deleteFolder(folder){
                console.log('deleteFolder: ', folder);
                this.$fapi.delete('boardpacket/files/' + folder).then((response) =>{
                    console.log('deleteFolder.teresponse: ', response);
                    this.focusedFolder = '';
                    this.readFiles();
                }).catch((error) => {
                    console.log('deleteFolder.error: ', error);                    
                });
            

            },
            filesInFolder(folder){
                let files = [];
                for(var ii = 0; ii < this.files.length; ii++){
                    if(this.files[ii].folder == folder)
                        files.push(this.files[ii]);
                }
                return files;
            },
            formatDate(raw){
                let date = new Date(raw * 1000).toLocaleDateString("en-US", {
                    day: "2-digit",
                    month: "2-digit"                
                });
                return date;
            },
            formatTime(raw){
                let date = new Date(raw * 1000).toLocaleDateString("en-US", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",                    
                });
                let time = new Date(raw * 1000).toLocaleTimeString("en-US", {                
                    hour: "2-digit",
                    minute: "2-digit"
                });
                return date + ' ' + time;
            },
            async readFiles(){
                let fetched = await this.$fapi.get('boardpacket/files');
                console.log('fetched: ', fetched);
                let items = fetched.data.data;
                this.folders = [];
                this.files = [];

                for(var ii = 0; ii < items.length; ii++){
                    if(items[ii].type == 'BoardPacketFolder')
                        this.folders.push(items[ii].attributes.name);
                    else if(items[ii].type == 'BoardPacketFile'){
                        let item = items[ii];
                        item.folder = '';
                        this.files.push(item);
                    }
                }
                //Load files for all folders
                for(var ii = 0; ii < this.folders.length; ii++){
                    this.readFolderFiles(this.folders[ii])
                }
            },
            async readFolderFiles(folder){
                console.log('readFolderFiles: ', folder);
                let fetched = await this.$fapi.get('boardpacket/files/' + folder);
                console.log('fetched: ', fetched);
                let items = fetched.data.data;
                for(var ii = 0; ii < items.length; ii++){
                    if(items[ii].type == 'BoardPacketFile'){
                        let item = items[ii];
                        item.folder = folder;
                        this.files.push(item);
                    }
                }
            },
            uploadFile(data){
                this.uploadError = ''
                this.uploadNotice = ''
                let files = this.$refs.fileUpload.getFiles();

                if(files.error){                    
                    this.uploadError = files.detail;
                    return;
                }

                //Make sure any files selected
                if(files.length == 0){
                    this.uploadError = 'No files selected';
                    return;
                }

                let folder = this.$refs.destinationFolder.getValue();
                console.log('folder: ', folder);

                let url = 'boardpacket/files';
                if(folder && folder.length > 0)
                    url += '/' + folder;

                var formData = new FormData();
                //formData.append("file", files[0]);

                for(var ii = 0; ii < files.length; ii++){
                    formData.append('files[]', files[ii]);
                }

                console.log('files: ', files);
                console.log('formData: ', formData);
                formData.forEach((value, key) => {
                    console.log(key, value);
                });

                this.$fapi.post(url, formData, {
                    headers: {
                    'Content-Type': 'multipart/form-data'
                    }
                }).then((response) =>{
                    console.log('response: ', response);
                    this.uploadNotice = 'File successfully uploaded';
                    this.readFiles();
                    this.$refs.fileUpload.clear();
                    this.$refs.destinationFolder.clear();
                }).catch((error) => {
                    this.uploadError = error.response.data.errors[0].detail;
                    console.log('error: ', error);                    
                });
            }
        },  
		created(){
            document.title = 'Board Control'
            this.readFiles();
            this.addFolder();
		}, 

        setup(){            
            const files = ref([])
            const focusedFolder = ref('')
            const folders = ref([]);

            const folderError = ref('')
            const folderNotice = ref('')
            const uploadError = ref('')
            const uploadNotice = ref('')
            const mode = ref('')
            const updateActiveFolders = ref(0)

            return { 
                files,
                focusedFolder,
                folderError,
                folderNotice,
                folders,
                mode,
                updateActiveFolders,
                uploadError,
                uploadNotice
            }

        }
	}
	
</script>
